import React, { Component } from 'react';
import { Link } from "react-router-dom";


class PartnerLogo extends Component {
    render() {
        return (
            <>
                <div className="partner-area ptb-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="single-partner-item">
                                <a
                                    href="https://www.hanyang.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/hanyang.png")}
                                        alt="hanyang"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/hanyang.png")}
                                        alt="hanyang"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.hanyang.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/hanyangerica.png")}
                                        alt="hanyangerica"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/hanyangerica.png")}
                                        alt="hanyangerica"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.bu.ac.kr/"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/baekseok.png")}
                                        alt="baekseok"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/baekseok.png")}
                                        alt="baekseok"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.bscu.ac.kr/"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/baekseokculture.png")}
                                        alt="baekseokculture"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/baekseokculture.png")}
                                        alt="baekseokculture"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="http://www.calvin.ac.kr/"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/calvin.png")}
                                        alt="calvin"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/calvin.png")}
                                        alt="calvin"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.dongyang.ac.kr/"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/dongyangmirae.png")}
                                        alt="dongyangmirae"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/dongyangmirae.png")}
                                        alt="dongyangmirae"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.eulji.ac.kr/?view=pc"
                                    rel="noreferrer noopener"
                                    target="_blank"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/eulji.png")}
                                        alt="eulji"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/eulji.png")}
                                        alt="eulji"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.induk.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/induk.png")}
                                        alt="induk"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/induk.png")}
                                        alt="induk"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://ukp.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/kimpo.png")}
                                        alt="kimpo"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/kimpo.png")}
                                        alt="kimpo"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.kwu.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/kwangjuwomen.png")}
                                        alt="kwangjuwomen"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/kwangjuwomen.png")}
                                        alt="kwangjuwomen"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="http://ltu.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/luther.png")}
                                        alt="luther"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/luther.png")}
                                        alt="luther"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.smu.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/sangmyung.png")}
                                        alt="sangmyung"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/sangmyung.png")}
                                        alt="sangmyung"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.skuniv.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/seokyeong.png")}
                                        alt="seokyeong"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/seokyeong.png")}
                                        alt="seokyeong"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://www.swwu.ac.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/suwonwomen.png")}
                                        alt="suwonwomen"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/suwonwomen.png")}
                                        alt="suwonwomen"
                                    />
                                </a>
                            </div>

                            <div className="single-partner-item">
                                <a
                                    href="https://inchang.sen.hs.kr/"
                                    target="_blank"
                                    rel="noreferrer noopener"
                                >
                                    <img
                                        src={require("../../images/partner-image/black/inchang.png")}
                                        alt="inchang"
                                    />
                                    <img
                                        src={require("../../images/partner-image/black/inchang.png")}
                                        alt="inchang"
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PartnerLogo;