import React, { Component } from "react";

class ProductDetailsMedicalVr extends Component {
    state = {
        isOpen: false,
        photoIndex: 0,
        isOpenImage: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        // const { photoIndex, isOpenImage } = this.state;
        return (
            <>
                <div className="project-details-area ptb-100">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/dentvr.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div> */}

                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/dentvr2.png")}
                                        alt="projects"
                                    />
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>Dentistry VR</h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            한국보건의료인국가시험원 (이하
                                            국시원)의 지원을 통해서 개발된
                                            치위생사의 술기시험을 VR로 수행하는
                                            시스템입니다. 의료인 술기시험의
                                            경우에는 직접 환자를 대상으로 하는
                                            경우가 많으나, 안전 문제 및 평가
                                            체계의 공정성 시비를 해결하고자
                                            정량화되고 객관적인 시험 운용
                                            시스템을 요구하고 있습니다.
                                            서울대학교 치의과생을 대상으로
                                            “마취”, “발치”, “스케일링”의 3개 VR
                                            술기시험의 적합성을 검증 하였고,
                                            현재 전문대학교의 치위생사 교육에
                                            사용하고 있습니다. 기업의 vocational
                                            교육, 실험실습 교육을 지원하는
                                            VR콘텐츠 제작의 노하우를 가지고 있는
                                            러닝시그널을 통해서 기업 및 학교에
                                            필요한 맞춤형 VR콘텐츠를 제작해
                                            보세요.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>Dementia VR</h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            경도인지장애를 판별하는 VR콘텐츠로,
                                            5분동안 VR로 구성된 버스타기와 ATM을
                                            사용하는 작업을 수행하게 하여,
                                            인공지능이 인지장애 여부를 판단함.
                                            한양대학교 병원과 협업으로 개발
                                            되었으며 현재 성동치매지원센터 등
                                            다수의 센터에서 사용중인
                                            시스템입니다. (10-1912020 등록특허)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductDetailsMedicalVr;
