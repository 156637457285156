import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ContactForm from "../components/Contact/ContactForm";
import Footer from "../components/Layout/Footer";

class Contact extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Contact"
                    breadcrumbTextOne="Contact"
                    breadcrumbUrl="/contact#"
                    breadcrumbTextTwo="Contact"
                />

                <ContactForm />
                <Footer />
            </>
        );
    }
}

export default Contact;
