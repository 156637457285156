import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

class Navbar extends Component {
    // Navbar
    _isMounted = false;
    state = {
        display: false,
        collapsed: true,
    };
    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    };
    componentDidMount() {
        let elementId = document.getElementById("navbar");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
        window.scrollTo(0, 0);
    }
    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        // let { products } = this.props;
        const { collapsed } = this.state;
        const classOne = collapsed
            ? "collapse navbar-collapse"
            : "collapse navbar-collapse show";
        const classTwo = collapsed
            ? "navbar-toggler navbar-toggler-right collapsed"
            : "navbar-toggler navbar-toggler-right";

        return (
            <>
                <div id="navbar" className="navbar-area">
                    <div className="adani-nav">
                        <div className="container">
                            <nav className="navbar navbar-expand-md navbar-light">
                                <Link to="/" className="navbar-brand">
                                    <img
                                        src={require("../../images/logo-50.png")}
                                        className="black-logo"
                                        alt="logo"
                                    />
                                </Link>

                                <button
                                    onClick={this.toggleNavbar}
                                    className={classTwo}
                                    type="button"
                                    data-toggle="collapse"
                                    data-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="icon-bar top-bar"></span>
                                    <span className="icon-bar middle-bar"></span>
                                    <span className="icon-bar bottom-bar"></span>
                                </button>

                                <div
                                    className={classOne}
                                    id="navbarSupportedContent"
                                >
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <Link
                                                to="/about"
                                                className="nav-link"
                                            >
                                                LearningSignal
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/about"
                                                        className="nav-link"
                                                    >
                                                        About
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/team"
                                                        className="nav-link"
                                                    >
                                                        Team
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="/product-passfinder"
                                                className="nav-link"
                                            >
                                                Product
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/product-passfinder"
                                                        className="nav-link"
                                                    >
                                                        PassFinder
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="/product-lmsanalytics"
                                                        className="nav-link"
                                                    >
                                                        LMS & Analytics
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to="/product-vrmetaverse"
                                                        className="nav-link"
                                                    >
                                                        VR & Metaverse
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="/partner"
                                                className="nav-link"
                                            >
                                                Partner
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link
                                                to="/contact"
                                                className="nav-link"
                                            >
                                                Contact
                                            </Link>

                                            <ul className="dropdown-menu">
                                                <li className="nav-item">
                                                    <Link
                                                        to="/contact"
                                                        className="nav-link"
                                                    >
                                                        Contact
                                                    </Link>
                                                </li>

                                                <li className="nav-item">
                                                    <Link
                                                        to="/request"
                                                        className="nav-link"
                                                    >
                                                        Request A Demo
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state)=>{
    return{
        products: state.addedItems
    }
}

export default connect(mapStateToProps)(Navbar);

// export default Navbar;
