import React, { Component } from 'react';
import { Link } from "react-router-dom";

import SidebarModal from "../SidebarModal/SidebarModal";

class PageHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarModal: false,
        };
    }

    toggleModal = () => {
        this.setState({
            sidebarModal: !this.state.sidebarModal,
        });
    };

    render() {
        let {
            pageTitle,
            pageSummary,
            breadcrumbTextOne,
            breadcrumbTextTwo,
            breadcrumbUrl,
            type,
        } = this.props;

        return (
            <>
                <div className="page-title-area page-title-bg2">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="page-title-content">
                                    <h2>{pageTitle}</h2>
                                    <ul>
                                        {breadcrumbTextOne && (
                                            <li>
                                                <Link to={breadcrumbUrl}>
                                                    <a>{breadcrumbTextOne}</a>
                                                </Link>
                                            </li>
                                        )}
                                        <li>{breadcrumbTextTwo}</li>
                                    </ul>
                                    <br />
                                    <p>{pageSummary}</p>
                                    {type && (
                                        <button
                                            type="button"
                                            className="default-btn"
                                            onClick={this.toggleModal}
                                        >
                                            브로셔 다운로드 <span></span>
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Right Sidebar Modal */}
                <SidebarModal
                    onClick={this.toggleModal}
                    active={this.state.sidebarModal ? "active" : ""}
                    type={type}
                />
            </>
        );
    }
}

export default PageHeader;