import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ProductDetailsLearningAnalytics from "../components/ProjectDetails/ProductDetailsLearningAnalytics";
import Footer from "../components/Layout/Footer";

class LearningAnalyticsDetails extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Learning Analytics"
                    breadcrumbTextOne="LMS & Analytics"
                    breadcrumbUrl="/product-lmsanalytics"
                    breadcrumbTextTwo="Learning Analytics"
                />

                <ProductDetailsLearningAnalytics />

                <Footer />
            </>
        );
    }
}

export default LearningAnalyticsDetails;
