import React, { Component } from "react";
import { Link } from "react-router-dom";


class ProductsCardLmsAnalytics extends Component {
    render() {
        return (
            <>
                <div className="projects-area ptb-100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/canvaslms.jpg")}
                                        alt="image"
                                    />

                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-canvaslms">
                                                <a>LearningSignal Canvas LMS</a>
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            LMS & Analytics
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/learninganalytics.jpg")}
                                        alt="image"
                                    />

                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-learninganalytics">
                                                <a>Learning Analytics</a>
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            LMS & Analytics
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductsCardLmsAnalytics;
