import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ProductDetailsChemVr from "../components/ProjectDetails/ProductDetailsChemVr";
import Footer from "../components/Layout/Footer";

class ChemVrDetails extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Chem VR"
                    breadcrumbTextOne="VR & Metaverse"
                    breadcrumbUrl="/product-vrmetaverse"
                    breadcrumbTextTwo="Chem VR"
                    type="BROCHURE_CHEMVR"
                />

                <ProductDetailsChemVr />

                <Footer />
            </>
        );
    }
}

export default ChemVrDetails;
