import React, { Component } from 'react';
import { API } from "aws-amplify";
import { createReceiver as createReceiverMutation } from "../../graphql/mutations";

class ContactForm extends Component {
    constructor() {
        super();
        this.state = {
            type: "CONTACT",
            name: "",
            email: "",
            jobTitle: "",
            companyName: "",
            title: "",
            content: "",
        };
    }

    onChangeStateValue = (e) => {
        this.setState(() => {
            return { ...this.state, [e.target.name]: e.target.value };
        });
    };

    saveContact = async (e) => {
        if (
            !this.state.name ||
            !this.state.email ||
            !this.state.jobTitle ||
            !this.state.companyName ||
            !this.state.title ||
            !this.state.content
        ) {
            return;
        }

        await API.graphql({
            query: createReceiverMutation,
            variables: {
                input: {
                    type: this.state.type,
                    name: this.state.name,
                    email: this.state.email,
                    jobTitle: this.state.jobTitle,
                    companyName: this.state.companyName,
                    title: this.state.title,
                    content: this.state.content,
                },
            },
        });

        alert("문의가 접수되었습니다. 모든 문의는 72시간 내 처리됩니다.");
        window.location.replace("/contact");
    };

    render() {
        return (
            <section className="contact-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">Contact Us</span>
                        <h2>Drop us Message for any Query</h2>
                        <p>모든 문의는 72시간 내 처리됩니다.</p>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="contact-form">
                                <form
                                    id="contactForm"
                                    onSubmit={() => {
                                        return false;
                                    }}
                                >
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="name"
                                                    id="name"
                                                    className="form-control"
                                                    placeholder="이름"
                                                    onChange={
                                                        this.onChangeStateValue
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="email"
                                                    name="email"
                                                    id="email"
                                                    className="form-control"
                                                    placeholder="이메일"
                                                    onChange={
                                                        this.onChangeStateValue
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="jobTitle"
                                                    id="job_title"
                                                    className="form-control"
                                                    placeholder="직책"
                                                    onChange={
                                                        this.onChangeStateValue
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="companyName"
                                                    id="company_name"
                                                    className="form-control"
                                                    placeholder="회사이름"
                                                    onChange={
                                                        this.onChangeStateValue
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    name="title"
                                                    id="title"
                                                    className="form-control"
                                                    placeholder="제목"
                                                    onChange={
                                                        this.onChangeStateValue
                                                    }
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea
                                                    className="form-control"
                                                    name="content"
                                                    id="content"
                                                    rows="5"
                                                    placeholder="문의 내용"
                                                    onChange={
                                                        this.onChangeStateValue
                                                    }
                                                    required
                                                ></textarea>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button
                                                type="submit"
                                                // type="button"
                                                className="default-btn"
                                                onClick={this.saveContact}
                                            >
                                                Send Message <span></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactForm;