import React, { Component } from "react";
import { API } from "aws-amplify";
import { createReceiver as createReceiverMutation } from "../../graphql/mutations";

export class SidebarModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: props.type,
            name: "",
            email: "",
            jobTitle: "",
            companyName: "",
            title: "",
            content: "",
        };
    }

    onChangeStateValue = (e) => {
        this.setState(() => {
            return { ...this.state, [e.target.name]: e.target.value };
        });
    };

    saveBrochureRequest = async (e) => {
        await API.graphql({
            query: createReceiverMutation,
            variables: {
                input: {
                    type: this.state.type,
                    name: this.state.name,
                    email: this.state.email,
                    jobTitle: this.state.jobTitle,
                    companyName: this.state.companyName,
                    title: this.state.title,
                    content: this.state.content,
                },
            },
        });
        this.closeModal();
        alert("요청하신 브로셔가 메일로 전송되었습니다.");
    };

    modal = {
        view: false,
    };

    closeModal = () => {
        this.props.onClick(this.modal.view);
    };

    render() {
        return (
            <>
                <div className={`sidebar-modal ${this.props.active}`}>
                    <div className="sidebar-modal-inner">
                        <div className="sidebar-about-area">
                            <div className="title">
                                <h2>브로셔 다운로드</h2>
                                <p>
                                    이름, 이메일, 직책, 회사이름을 기입하신 후
                                    확인을 누르시면 브로셔를 보내드립니다.
                                </p>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-12 col-md-12">
                                <div className="contact-form">
                                    <form
                                        id="contactForm"
                                        onSubmit={() => {
                                            return false;
                                        }}
                                    >
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        id="validationDefault01"
                                                        className="form-control"
                                                        placeholder="이름"
                                                        onChange={
                                                            this
                                                                .onChangeStateValue
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        type="email"
                                                        name="email"
                                                        id="email"
                                                        className="form-control"
                                                        placeholder="이메일"
                                                        onChange={
                                                            this
                                                                .onChangeStateValue
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="jobTitle"
                                                        id="job_title"
                                                        className="form-control"
                                                        placeholder="직책"
                                                        onChange={
                                                            this
                                                                .onChangeStateValue
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <input
                                                        type="text"
                                                        name="companyName"
                                                        id="company_name"
                                                        className="form-control"
                                                        placeholder="회사이름"
                                                        onChange={
                                                            this
                                                                .onChangeStateValue
                                                        }
                                                        required
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-lg-12 col-md-12 text-center">
                                                <button
                                                    type="submit"
                                                    // type="button"
                                                    className="default-btn"
                                                    onClick={
                                                        this.saveBrochureRequest
                                                    }
                                                >
                                                    확인 <span></span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        <span
                            onClick={this.closeModal}
                            className="close-btn sidebar-modal-close-btn"
                        >
                            <i className="flaticon-close"></i>
                        </span>
                    </div>
                </div>
            </>
        );
    }
}

export default SidebarModal;
