import React, { Component } from 'react';
import { Link } from "react-router-dom";
import dynamic from "next/dynamic";

const OwlCarousel = dynamic(import("react-owl-carousel3"));

const options = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    mouseDrag: false,
    touchDrag: false,
    responsive: {
        0: {
            items: 1,
        },
        576: {
            items: 2,
        },
        1200: {
            items: 3,
        },
    },
};

class Projects extends Component {
    _isMounted = false;
    state = {
        display: false,
    };

    componentDidMount() {
        this._isMounted = true;
        this.setState({ display: true });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        return (
            <>
                <section className="projects-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <span className="sub-title">Product</span>
                            <h2>Proud Products That Make Us Stand Out</h2>
                            <p>
                                러닝시그널에서 개발한 혁신 기술이 적용된
                                제품들을 소개합니다
                            </p>
                        </div>
                    </div>

                    <div className="container">
                        {this.state.display ? (
                            <OwlCarousel
                                className="projects-slides owl-carousel owl-theme"
                                {...options}
                            >
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/rics.jpg")}
                                        alt="rics"
                                    />
                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-rics">
                                                Real-time learning Information
                                                Collection System
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            PassFinder
                                        </span>
                                    </div>
                                </div>

                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/aaii.jpg")}
                                        alt="aaii"
                                    />
                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-aaii">
                                                Automated AI-Informed
                                                Intervention
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            PassFinder
                                        </span>
                                    </div>
                                </div>

                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/canvaslms.jpg")}
                                        alt="canvaslms"
                                    />
                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-canvaslms">
                                                LearningSignal Canvas LMS
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            LMS & Analytics
                                        </span>
                                    </div>
                                </div>

                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/learninganalytics.jpg")}
                                        alt="learninganalytics"
                                    />
                                    <div className="projects-content">
                                        <h3>
                                            <Link to="product-details-learninganalytics">
                                                Learning Analytics
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            LMS & Analytics
                                        </span>
                                    </div>
                                </div>

                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/chemvr.jpg")}
                                        alt="chemvr"
                                    />
                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-chemvr">
                                                Chem VR
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            VR & Metaverse
                                        </span>
                                    </div>
                                </div>

                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/medicalvr.jpg")}
                                        alt="medicalvr"
                                    />
                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-medicalvr">
                                                Medical VR
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            VR & Metaverse
                                        </span>
                                    </div>
                                </div>
                            </OwlCarousel>
                        ) : (
                            ""
                        )}
                    </div>
                </section>
            </>
        );
    }
}

export default Projects;