import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ProductDetailsAaei from "../components/ProjectDetails/ProductDetailsAaii";
import Footer from "../components/Layout/Footer";

class AaeiDetails extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Automated AI-Informed Intervention"
                    breadcrumbTextOne="PassFinder"
                    breadcrumbUrl="/product-passfinder"
                    breadcrumbTextTwo="AAII"
                    type="BROCHURE_AAII"
                />

                <ProductDetailsAaei />

                <Footer />
            </>
        );
    }
}

export default AaeiDetails;
