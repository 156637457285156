import React, { Component } from 'react';
import Navbar from "../components/Layout/Navbar";
import PageHeader from '../components/Common/PageHeader';
import ProductDetailsRics from '../components/ProjectDetails/ProductDetailsRics';
import Footer from '../components/Layout/Footer';
 
class RicsDetails extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Real-time learning Information Collection System"
                    breadcrumbTextOne="PassFinder"
                    breadcrumbUrl="/product-passfinder"
                    breadcrumbTextTwo="RICS"
                    type="BROCHURE_RICS"
                />

                <ProductDetailsRics />

                <Footer />
            </>
        );
    }
}

export default RicsDetails;