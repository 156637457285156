import React, { Component } from 'react';
import { Link } from "react-router-dom";


class FeaturedSolutions extends Component {
    render() {
        return (
            <section className="pt-100 pb-70">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">What We Offer</span>
                        <h2>Our Featured Solutions</h2>
                        <p>
                            러닝시그널은{" "}
                            <strong>
                                학습콘텐츠에 대한 학생별 학습과정 시뮬레이션을
                                기반으로 자동화·맞춤화된 학습개입 모듈을 제공
                            </strong>
                            합니다.{" "}
                            <strong>
                                모든 학생이 성공할 수 있도록 AI기반의 학습분석을
                                교강사에게 제공
                            </strong>
                            하여 최적의 학습경험과 최고의 콘텐츠 설계가
                            가능하도록 지원합니다. 러닝시그널의{" "}
                            <strong>
                                LMS/LXP를 통해서 제공되는 VR/AR/MR 실감형 콘텐츠
                            </strong>
                            도 학생별로 최적화된 학습경험을 만들어 드립니다.
                        </p>
                    </div>

                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="featured-solution-card">
                                <i className="bx bxs-analyse"></i>
                                <h3>
                                    {/* <Link to="/product-passfinder"> */}
                                    <a>PassFinder</a>
                                    {/* </Link> */}
                                </h3>
                                <p>
                                    학생별로 다른 학습 상황에 맞추어 필요한 학습
                                    과정을 지원하는 인공지능을 탑재하여,
                                    학생들의 자기조절 학습(self-regulated
                                    learning)이 가능하도록 최적화된 시스템을
                                    제공
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="featured-solution-card">
                                <i className="bx bx-message"></i>
                                <h3>
                                    {/* <Link to="/product-vrmetaverse"> */}
                                    <a>LMS & Analytics</a>
                                    {/* </Link> */}
                                </h3>
                                <p>
                                    모든 학생의 성공을 위해서 한국 교육 상황에
                                    최적화된 LMS(Learning Management System)를
                                    자체 개발 하여, 인공지능을 통한 학생별
                                    학습분석 및 교육효과 분석 시스템을 제공
                                </p>
                            </div>
                        </div>

                        <div className="col-lg-4 col-sm-6">
                            <div className="featured-solution-card">
                                <i className="bx bx-expand"></i>
                                <h3>
                                    {/* <Link to="/product-lmsanalytics"> */}
                                    <a>VR & Metaverse</a>
                                    {/* </Link> */}
                                </h3>
                                <p>
                                    VR을 이용한 과학 및 공학 실험시스템 구축을
                                    통해서 학교 / 기업에 안전하고 효과적인 실기
                                    교육 시스템을 개발 판매, 동시에 기업별
                                    요구사항에 맞는 in-house 개발 수행
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default FeaturedSolutions;