import React, { Component } from 'react';
import { Link } from "react-router-dom";


class AboutUs extends Component {
    render() {
        return (
            <section className="choose-area-two pb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="choose-content">
                                <div className="section-title text-left">
                                    <span className="sub-title">About</span>
                                    <h2>
                                        Engaging new learning design through
                                        innovative Technology
                                    </h2>

                                    <p>
                                        최신의 혁신 AI/VR/AR/MR 기술을 개발하고,
                                        이를 학습과정(대학교, 기업 등)에
                                        적용하여, 최적의 학습경험을 제공해 주는
                                        러닝시그널과 함께 새로운 교육을 만들어
                                        보세요.
                                    </p>

                                    <p>
                                        러닝시그널은 인공지능 전문가와 가상현실
                                        전문가들이 모인 교육 메타버스
                                        기업입니다. 인공지능팀의 데이터
                                        분석능력과 가상현실팀의 창의 학습공간
                                        설계능력을 사용해서 최고의 학습경험을
                                        제공합니다.
                                    </p>
                                </div>

                                <div className="choose-btn">
                                    <Link to="/about" className="default-btn">
                                        About LearningSignal <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-12">
                            <div className="choose-image">
                                <img
                                    src={require("../../images/machine-learning/about-front.png")}
                                    alt="about-front"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default AboutUs;