import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import Footer from "../components/Layout/Footer";
import { API, Storage } from "aws-amplify";
import * as queries from "../graphql/queries";

class BrochureDownload extends Component {
    constructor() {
        super();
        this.state = {
            text: "브로셔가 곧 다운로드됩니다.",
        };
    }

    async componentDidMount() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const receiverID = params.get("ri");
        let fileName = "";
        let receiver = null;

        try {
            receiver = await API.graphql({
                query: queries.getReceiver,
                variables: { id: receiverID },
            });
        } catch (error) {
            this.state.text = "잘못된 접근입니다.";
            return;
        }

        const type = receiver.data.getReceiver.type;

        if (type === "BROCHURE_PASSFINDER") {
            fileName = "PASSFINDER_BROCHURE.pdf";
        } else if (type === "BROCHURE_LMSLA") {
            fileName = "LMS_ANALYTICS_BROCHURE.pdf";
        } else if (type === "BROCHURE_CHEMVR") {
            fileName = "CHEMVR_BROCHURE.pdf";
        } else if (type === "BROCHURE_RICS") {
            fileName = "RICS_BROCHURE.pdf";
        } else if (type === "BROCHURE_AAII") {
            fileName = "AAII_BROCHURE.pdf";
        }

        const diffDay =
            new Date().getDay() -
            new Date(receiver.data.getReceiver.createdAt).getDay();

        if (diffDay > 3) {
            this.state.text = "다운로드 기한이 지났습니다.";
        } else if (!receiver || fileName === "") {
            this.state.text = "잘못된 접근입니다.";
        } else {
            setTimeout(async () => {
                const fileURL = await Storage.get(fileName);

                fetch(fileURL, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/pdf",
                    },
                })
                    .then((response) => response.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(
                            new Blob([blob])
                        );
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.parentNode.removeChild(link);
                    });
            }, 1500);
        }
    }

    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Brochure Download"
                    breadcrumbTextOne=""
                    breadcrumbUrl=""
                    breadcrumbTextTwo={this.state.text}
                />

                <Footer />
            </>
        );
    }
}

export default BrochureDownload;
