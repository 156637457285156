import React, { Component } from 'react';
import NavbarTwo from "../components/Layout/NavbarTwo";
import Banner from '../components/HomeEight/Banner';
import FeaturedSolutions from '../components/HomeEight/FeaturedSolutions';
import AboutUs from "../components/HomeEight/AboutUs";
import Projects from "../components/HomeEight/Projects";
import Footer from '../components/Layout/Footer';

class Index extends Component {
    render() {
        return (
            <>
                <NavbarTwo />
                <Banner />
                <FeaturedSolutions />
                <Projects />
                <AboutUs />
                <Footer />
            </>
        );
    }
}

export default Index;