import React, { Component } from "react";
import ModalVideo from "react-modal-video";

class AboutArea extends Component {
    state = {
        isOpen: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        return (
            <section className="about-area-two ptb-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="about-image">
                                <img
                                    src={require("../../images/aboutls.jpg")}
                                    alt="aboutls"
                                    className="rounded-10"
                                />
                            </div>
                        </div>

                        <div className="col-lg-7 col-md-12">
                            <div className="about-content">
                                <div className="section-title text-left">
                                    <span className="sub-title">About</span>
                                    <h2>We Design Learning</h2>
                                    <p>
                                        러닝시그널은{" "}
                                        <strong>
                                            AI 및 VR 기술을 적용하여 학생별로
                                            최적의 학습활동을 설계
                                        </strong>
                                        해 드립니다. 이를 통해서 기업 및 학교는
                                        가장 빠르고, 저렴하고, 효과적으로
                                        교육활동을 수행할 수 있습니다. 어려운
                                        의사결정은 AI에게, 창의적 학습콘텐츠는
                                        VR로, 학습활동 설계는 러닝시그널의
                                        LMS/LXP와 함께 최고의 교육을
                                        만들어보세요.
                                    </p>
                                </div>
                                <div className="about-text">
                                    <h4>Who We Teams Are</h4>
                                    <p>
                                        · 인공지능 팀 : 학습분석, 학습과정분석
                                        및 시뮬레이션, 학습개입 모듈 개발
                                        <br />· VR/AR/MR 팀: VR 콘텐츠 개발 팀
                                        <br />· LMS/LXP 팀: 기관 LMS연동 및
                                        LMS/LXP 구축·커스터마이징
                                    </p>
                                </div>
                                <div className="about-text">
                                    <h4>Our History</h4>
                                    <p>
                                        · 2019 – Chem VR 개발 (10종)
                                        <br></br>· 2021 – Science VR-College
                                        개발 (8종), PassFinder 개발, Intelligent
                                        Assistant 개발 (특허 출원 4건)
                                        <br></br>· 2022 – VR-LMS 개발 (특허출원
                                        4건)
                                    </p>
                                </div>
                                <div className="about-text">
                                    <h4>Our Mission Statement</h4>
                                    <p>
                                        실패하는 학생은 없다. 다만 실패하는
                                        교육만 있을 뿐. <br />
                                        따라서 우리는{" "}
                                        <strong>
                                            모든 학생의 성공을 위한 교육을 설계
                                        </strong>
                                        하는 것을 목표로 한다.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* If you want change the video need to update below videoID */}
                <ModalVideo
                    channel="youtube"
                    isOpen={this.state.isOpen}
                    videoId="szuchBiLrEM"
                    onClose={() => this.setState({ isOpen: false })}
                />
            </section>
        );
    }
}

export default AboutArea;
