/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createReceiver = /* GraphQL */ `
  mutation CreateReceiver(
    $input: CreateReceiverInput!
    $condition: ModelReceiverConditionInput
  ) {
    createReceiver(input: $input, condition: $condition) {
      id
      type
      name
      email
      jobTitle
      companyName
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const updateReceiver = /* GraphQL */ `
  mutation UpdateReceiver(
    $input: UpdateReceiverInput!
    $condition: ModelReceiverConditionInput
  ) {
    updateReceiver(input: $input, condition: $condition) {
      id
      type
      name
      email
      jobTitle
      companyName
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const deleteReceiver = /* GraphQL */ `
  mutation DeleteReceiver(
    $input: DeleteReceiverInput!
    $condition: ModelReceiverConditionInput
  ) {
    deleteReceiver(input: $input, condition: $condition) {
      id
      type
      name
      email
      jobTitle
      companyName
      title
      content
      createdAt
      updatedAt
    }
  }
`;
