import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import Footer from "../components/Layout/Footer";
import ProductsCardLmsAnalytics from "../components/Projects/ProductsCardLmsAnalytics";

class ProductLmsAnalytics extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="LearningSignal Canvas LMS & Education Analytics"
                    breadcrumbTextOne="Product"
                    breadcrumbUrl="/product-lmsanalytics#"
                    breadcrumbTextTwo="LMS & Analytics"
                    pageSummary="(주)러닝시그널은 모든 학생의 성공을 지향합니다. Universal Education을 위해서 러닝시그널은 한국상황에 맞는 LMS (Learning Management System)을 구축하였고, 인공지능을 활용하여, 학생별 학습데이터를 분석하여 최적의 학습과정을 지원합니다. 직관적인 인터페이스와 파놉토를 사용하는 LCMS (Learning Contents Management System)는 최적의 학습플랫폼을 제공하고 있습니다. "
                    link="https://drive.google.com/file/d/1euQ0JTi7VQPkNmFtdj4IJ_PW0RTYobrF/view?usp=sharing"
                    type="BROCHURE_LMSLA"
                />

                <ProductsCardLmsAnalytics />

                <Footer />
            </>
        );
    }
}

export default ProductLmsAnalytics;
