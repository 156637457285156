import React, { Component } from "react";

class Footer extends Component {
    render() {
        // let currentYear = new Date().getFullYear();
        return (
            <>
                <section className="footer-area">
                    <div className="container">
                        {/* <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget">
                                    <h3>Contact Info</h3>

                                    <ul className="footer-contact-info">
                                        <li>
                                            <i className="flaticon-phone-call"></i>
                                            <span>
                                                Mon to Fri : 10:00AM - 06:00PM
                                            </span>

                                            <a href="tel:1235421457852">
                                                +82 2 1111 1111
                                            </a>
                                        </li>
                                        <li>
                                            <i className="flaticon-email"></i>
                                            <span>Do You Have a Question?</span>
                                            <a href="mailto:contact@learningsignal.com">
                                                contact@learningsignal.com
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-footer-widget pl-5">
                                    <h3>Quick Links</h3>

                                    <ul className="footer-quick-links">
                                        <li>
                                            <Link to="/">
                                                <a>Home</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/about">
                                                <a>About</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/team">
                                                <a>Team</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/partner">
                                                <a>Partner</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/contact">
                                                <a>Contact</a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/request">
                                                <a>Demo</a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                                <div className="single-footer-widget pl-5">
                                    <h3>Partner</h3>

                                    <ul className="footer-instagram-post">
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img
                                                        src={require("../../images/partner-image/partner-inchang.png")}
                                                        alt="image"
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img
                                                        src={require("../../images/partner-image/partner-inchang.png")}
                                                        alt="image"
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img
                                                        src={require("../../images/partner-image/partner-inchang.png")}
                                                        alt="image"
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img
                                                        src={require("../../images/partner-image/partner-inchang.png")}
                                                        alt="image"
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img
                                                        src={require("../../images/partner-image/partner-inchang.png")}
                                                        alt="image"
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#">
                                                <a target="_blank">
                                                    <img
                                                        src={require("../../images/partner-image/partner-inchang.png")}
                                                        alt="image"
                                                    />
                                                </a>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div> */}

                        <div className="copyright-area">
                            <div className="row float-right">
                                <div className="col-rg-6 col-sm-6 col-md-6 float-right">
                                    <p>
                                        ​©2021 LearningSignal Inc. All rights
                                        reserved.
                                    </p>
                                </div>

                                <div className="col-lg-6 col-sm-6 col-md-6">
                                    <ul>
                                        <li>
                                            <p>(주) 러닝시그널</p>
                                        </li>
                                        <li>
                                            <p>대표 김현도</p>
                                        </li>
                                        <li>
                                            <p>사업자 등록번호 640-86-02120</p>
                                        </li>
                                        <li>
                                            <p>
                                                서울 강남구 논현로85길 52 역삼
                                                푸르지오 시티 601호
                                            </p>
                                        </li>
                                        <li>
                                            <p>
                                                문의 contact@learningsignal.com
                                            </p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Footer;
