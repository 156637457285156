/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReceiver = /* GraphQL */ `
  query GetReceiver($id: ID!) {
    getReceiver(id: $id) {
      id
      type
      name
      email
      jobTitle
      companyName
      title
      content
      createdAt
      updatedAt
    }
  }
`;
export const listReceivers = /* GraphQL */ `
  query ListReceivers(
    $filter: ModelReceiverFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReceivers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        name
        email
        jobTitle
        companyName
        title
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
