import React, { Component } from "react";

class ProductDetailsAaei extends Component {
    state = {
        isOpen: false,
        photoIndex: 0,
        isOpenImage: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        // const { photoIndex, isOpenImage } = this.state;
        return (
            <>
                <div className="project-details-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/aaii.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/project6.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div> */}

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>
                                        Automated AI-Informed Intervention{" "}
                                        <br />
                                        (AAII: 인공지능 학습개입-촉진 모듈)
                                    </h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            모든 학생들의 학습 속도는 같지
                                            않습니다. 같은 내용을 순발력있게
                                            빠르게 학습하는 학생도 있고, 천천히
                                            학습하는 학생도 있습니다. 따라서
                                            하나의 정해진 교수법으로 모든 학생을
                                            성공시키는 것은 교수자에게도
                                            부담이고, 학생들에게도 효율적이지
                                            않습니다. AAII는 교수자의 부담을
                                            줄여주면서, 머신러닝과 딥러닝을
                                            사용하여 학생의 학습 속도에 맞추어서
                                            자동으로 학습 개입을 할 수 있는
                                            인공지능 모듈입니다. 이 모듈은
                                            캔버스 뿐만 아니라, 무들,
                                            블랙보드에도 사용이 가능합니다.
                                        </p>
                                        <p>
                                            AAII는 학생들의 학업성취도 및
                                            학생별/강의별 학습데이터를 기반으로
                                            머신러닝이 학생을 분류하고 학생의
                                            성공확률 (위험확률 혹은 실패확률)을
                                            매주 모니터링 하여, 필요 시
                                            학습자들에게 관련 학습 활동 및 학업
                                            촉진 문자/이메일/SNS를 자동으로
                                            발송하는 시스템입니다. 교수자는
                                            학습별/주차별/강의별로 학업을
                                            촉진시키고자 하는 내용을 미리
                                            작성하고 (필요시 기존 템플릿 사용
                                            가능), 해당하는 조건 (시점, 기준,
                                            횟수)을 기술하면, AAII가 학생들에게
                                            자동으로 학습개입을 수행합니다.
                                            (주)러닝시그널이 가지고 있는
                                            인공지능 기술을 활용하면,
                                            성공확률(위험확률, 실패확률)을
                                            구현하기 어려운 신규 교과목에도
                                            효율적인 학습 개입이 가능합니다.
                                        </p>
                                        <p>
                                            학습개입에 대한 학생들의 response도
                                            보안화된 방법으로 수집하여, 학생 및
                                            교수자에게 추가적인 개입을 할 수
                                            있도록 구현되어 있습니다.
                                        </p>
                                    </div>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요기능
                                        </h4>
                                        <p>
                                            · 학습개입 문자/이메일/SNS 발송 기능
                                            : 예) 3주차 “Perceptron”에 대한 퀴즈
                                            문제를 틀린 학생들에게 학습개입을
                                            위한 독려 이메일 및 관련 자료 자동
                                            발송
                                            <br />· 학습 데이터에 기반한 학생별
                                            위험확률 예측 및 자동 개입 기능 :
                                            예) 3주차 때 Perceptron 퀴즈에서
                                            50%이하를 획득한 학생의 경우 4주차에
                                            Multi-Level Perceptron (MLP)를
                                            이해하지 못할 확률 87%, 이 경우
                                            Perceptron에 대한 추가적인 자료를
                                            3주차에 발송
                                            <br />· 학습 데이터가 없는
                                            신규과목의 경우, rule-based 자동
                                            문자/이메일/SNS 발송 기능 : 예) 새로
                                            개설된 수업의 경우, 교수자가 직접
                                            formative / summative 평가결과에
                                            따른 학습개입 - 촉진 규칙을 설정하여
                                            문자/이메일/SNS 전송
                                            <br />· (주)러닝시그널 캔버스
                                            LMS사용시, 학습개입
                                            문자/이메일/SNS에 기술된 자료 “강의
                                            콘텐츠”에 자동 등재 기능 : 4주차 MLP
                                            를 이해하지 못한 학생에게 MLP에 대한
                                            유투브 링크를 전송한 경우, 해당
                                            유투브 링크를 4주차 “강의자료”에
                                            자동 등재
                                            <br />· PassFinder의 신호등 시스템
                                            사용시, 신호등 정보와 학생의 학습
                                            데이터 연동 기능 : 예) 3주차
                                            강의에서 MLP를 어렵다고 한 학생이
                                            학습활동이 저조하거나, 위험확률이
                                            높아지면 자동으로 관련자료 발송 및
                                            추가 정보 제공
                                        </p>
                                    </div>
                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요 연동 기능 및 효과
                                        </h4>
                                        <p>
                                            · 러닝시그널 캔버스 LMS와 연동 기능
                                            - LMS데이터/LRS데이터 기록
                                            <br />· 러닝시그널 캔버스 LMS와
                                            연동하여 학생별 성공확률 분석 제공:
                                            조기 개입 (early alert) 기능
                                            <br />· 학생 수준별 adaptive
                                            learning 기능
                                            <br />· 러닝시그널 캔버스 LMS
                                            강의자료 자동 업데이트
                                            <br />· 타 교과목과 연동시 학생별
                                            학업수행도 포트폴리오 구성 기능
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductDetailsAaei;
