import React, { Component } from "react";

class ProductDetailsLearningAnalytics extends Component {
    state = {
        isOpen: false,
        photoIndex: 0,
        isOpenImage: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        // const { photoIndex, isOpenImage } = this.state;
        return (
            <>
                <div className="project-details-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/canvaslms.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/project6.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div> */}

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>
                                        러닝시그널 학습분석 LTI 모듈 <br />
                                        (인공지능기반 Active Learning /
                                        Education Analytics)
                                    </h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            강의 및 수업에서 발생하는 학습 및
                                            행동 데이터 수집, LRS에 기록된
                                            데이터와의 조합, 데이터 시각화,
                                            데이터 분석 및 리포팅 기능, 학습
                                            개입을 위한 지능 생성을 지원하는
                                            가장 강력한 학습분석 모듈입니다.
                                            캔버스, 무들, 블랙보드에서 작동할 수
                                            있도록 LTI 기준으로 설계되어 있으며,
                                            학생들의 self-regulated learning
                                            지원, 교수자들의 교수법 설계지원
                                            (learning design analytics), 교수법
                                            분석 리포팅 기능을 탑재하고
                                            있습니다.
                                        </p>
                                    </div>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요기능
                                        </h4>
                                        <p>
                                            · 성공확률 (위험확률, 실패확룰)
                                            예측, 예측값에 따른 적응형 학습개입
                                            지원
                                            <br />· 학생별 강의 및 교과목 추천
                                            <br />· 학생 타입별 최적 학습 방법
                                            추천
                                            <br />· 최적의 수업 설계 지원 (퀴즈,
                                            강의구성 등)
                                            <br />· 개별 강의 분석 및 교수법
                                            분석 리포팅 기능
                                        </p>
                                    </div>
                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요 연동 기능 및 효과
                                        </h4>
                                        <p>
                                            · 러닝시그널 캔버스 LMS와 최적 조합
                                            운용
                                            <br />· VR실험 활동데이터 기반 자율
                                            실험 지원 기능
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductDetailsLearningAnalytics;
