import React, { Component } from 'react';
import Navbar from "../components/Layout/Navbar";
import PageHeader from '../components/Common/PageHeader';
import AboutArea from '../components/AboutTwo/AboutArea';
import Footer from '../components/Layout/Footer';

class About extends Component {
    render() {
        return (
            <>
                <Navbar />
                <PageHeader 
                    pageTitle="About" 
                    breadcrumbTextOne="LearningSignal" 
                    breadcrumbUrl="/" 
                    breadcrumbTextTwo="About" 
                />
                <AboutArea />
                <Footer />
            </>
        );
    }
}

export default About;