import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import RequestForm from "../components/Contact/RequestForm";
import Footer from "../components/Layout/Footer";

class Request extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Request A Demo"
                    breadcrumbTextOne="Contact"
                    breadcrumbUrl="/contact"
                    breadcrumbTextTwo="Request"
                />

                <RequestForm />
                <Footer />
            </>
        );
    }
}

export default Request;
