import React, { Component } from 'react';
import { Link } from "react-router-dom";


class TeamCardTwo extends Component {
    render() {
        return (
            <>
                <section className="team-area ptb-100">
                    <div className="container-fluid max-w-1700">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-team-box">
                                    <div className="image">
                                        <a href="#" rel="noreferrer noopener">
                                            <img
                                                src={require("../../images/team-image/team-hyundo.jpg")}
                                                alt="hyundo"
                                            />
                                        </a>
                                    </div>
                                    <div className="content">
                                        <h3>Kim Hyundo</h3>
                                        <span>Chief Executive Officer</span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-team-box">
                                    <div className="image">
                                        <a
                                            href="https://www.linkedin.com/in/donghoon-jung-2255521b9"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <img
                                                src={require("../../images/team-image/team-donghoon.jpg")}
                                                alt="donghoon"
                                            />
                                        </a>

                                        {/* <div className="social">
                                            <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </Link>
                                            <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </Link>
                                            <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </Link>
                                             <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="content">
                                        <h3>Jung Donghoon</h3>
                                        <span>Chief Technical Officer</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-team-box">
                                    <div className="image">
                                        {/* <Link to=> */}
                                        <a
                                            href="https://www.linkedin.com/in/hokyoung-ryu-0729a762"
                                            target="_blank"
                                            rel="noreferrer noopener"
                                        >
                                            <img
                                                src={require("../../images/team-image/team-hokyoung.jpg")}
                                                alt="hokyoung"
                                            />
                                        </a>
                                        {/* </Link> */}

                                        {/* <div className="social">
                                            <Link to="https://www.linkedin.com/in/hokyoung-ryu-0729a762">
                                                <a target="_blank">
                                                    <i className="fab fa-linkedin"></i>
                                                </a>
                                            </Link>
                                            <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-facebook-f"></i>
                                                </a>
                                            </Link>
                                            <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-twitter"></i>
                                                </a>
                                            </Link>
                                            <Link to="/team">
                                                <a target="_blank">
                                                    <i className="fab fa-instagram"></i>
                                                </a>
                                            </Link>
                                        </div> */}
                                    </div>
                                    <div className="content">
                                        <h3>Ryu Hokyoung</h3>
                                        <span>Chief Academic Officer</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default TeamCardTwo;