import React, { Component } from 'react';
import { Link } from "react-router-dom";


class Banner extends Component {
    render() {
        return (
            <div className="portfolio-agency-banner pa-bg1">
                <div className="d-table">
                    <div className="d-table-cell">
                        <div className="container mt-80">
                            <div className="banner-content text-center">
                                <h1 style={{ visibility: "hidden" }}>
                                    러닝시그널
                                </h1>
                                <h1>
                                    {/* AI-based Learning Experience Design */}
                                    러닝시그널은 AI 기반으로 최적의 학습과정을
                                    분석·설계·제안합니다
                                </h1>
                                <p>
                                    러닝시그널은 교육기관에서 제공하는
                                    교육콘텐츠의 전달과정과 학습효과를 측정하여
                                    학생별 맞춤형 러닝패스를 설계하고 최적의
                                    러닝패스를 지원하는 VR· AR· MR 기반의
                                    러닝콘텐츠를 제공합니다
                                </p>

                                <div className="banner-btn">
                                    <Link
                                        to="/request"
                                        className="default-btn-two"
                                    >
                                        Request A Demo <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;