import React, { Component } from 'react';
import Navbar from "../components/Layout/Navbar";
import PageHeader from '../components/Common/PageHeader';
import ProductsCardPassFinder from "../components/Projects/ProductsCardPassFinder";
import Footer from '../components/Layout/Footer';

class ProductPassfinder extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="PassFinder"
                    breadcrumbTextOne="Product"
                    breadcrumbUrl="/product-passfinder#"
                    breadcrumbTextTwo="PassFinder"
                    pageSummary="(주)러닝시그널은 모든 학생의 성공을 지향합니다. Universal Education을 위해서 인공지능 (Artificial Intelligence)와 Teacher Intelligence를 최적 조합하는 기술을 개발하고 있습니다. 교수자들은 학습 목표를 설계하고, 이 목표를 달성하기 위한 교수법 가설을 가지고 수업과 학습활동을 설계하게 됩니다. (주)러닝시그널이 개발한 PassFinder는 인공지능과 학생들의 학습 데이터를 보안화된 방법을 통해서 수집하여, 교수자들에게 필요한 정보를 필요한 시점에 제공하여, 모든 학생이 성공할 수 있도록 가이드할 수 있습니다. "
                    type="BROCHURE_PASSFINDER"
                />
                <ProductsCardPassFinder />
                <Footer />
            </>
        );
    }
}

export default ProductPassfinder;