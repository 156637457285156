import React, { Component } from 'react';
import { Link } from "react-router-dom";


class Error extends Component {
    render() {
        return (
            <>
                <section className="error-area">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="container">
                                <div className="error-content">
                                    <img
                                        src={require("../images/404.png")}
                                        alt="error"
                                    />

                                    <h3>페이지를 찾을 수 없습니다</h3>
                                    <p>
                                        죄송합니다. 현재 페이지에 오류가
                                        있습니다. <br />
                                        새로고침을 누르거나 관리자에게
                                        문의하세요.
                                    </p>

                                    <Link to="/" className="default-btn">
                                        Go to Home <span></span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Error;