import React, { Component } from "react";
import ModalVideo from "react-modal-video";

class ProductDetailsChemVr extends Component {
    state = {
        isOpen: false,
        photoIndex: 0,
        isOpenImage: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        // const { photoIndex, isOpenImage } = this.state;
        return (
            <>
                <div className="project-details-area ptb-100">
                    <div className="container">
                        <div className="row">
                            {/* <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/chemvr2.png")}
                                        alt="projects"
                                    />
                                </div>
                            </div> */}

                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/chemvr3.png")}
                                        alt="projects"
                                    />

                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.openModal();
                                        }}
                                        className="btn popup-youtube"
                                    >
                                        <i className="flaticon-play-button"></i>
                                    </div>
                                </div>
                            </div>

                            {/* If you want change the video need to update below videoID */}
                            <ModalVideo
                                channel="youtube"
                                isOpen={this.state.isOpen}
                                videoId="WsXHp60G8hg"
                                onClose={() => this.setState({ isOpen: false })}
                            />

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>
                                        CHEM VR (화학 VR 40종) 과 LMS 기반
                                        VR실험 운영시스템{" "}
                                    </h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            CHEM VR은 고등학교,대학교육,
                                            기업교육을 아우르는 원자, 분자,
                                            이온, 화학식, 열역학 및 화학 반응
                                            전반을 총 40여개의 VR 실험으로
                                            구축한 콘텐츠 백입니다. 캔버스 LMS와
                                            쉽게 연동되어, VR Headset 버전,
                                            모바일폰 버전, 360 동영상 시청모드
                                            등 기업 및 학교의 요구사항에 맞는
                                            다양한 상호작용을 제공하고 있습니다.
                                            한양대학교 화학과 및 공과대학과의
                                            협업을 통해서 실험실 교육과
                                            VR실험교육의 교육적 효과를 비교검증
                                            하였으며, 현재 한국 (한양대, 충북대
                                            등 12개의 종합대학 및 24개의
                                            전문대학에서 운용 중), 미국 (AP
                                            코스)에서 사용중에 있으며,
                                            카자흐스탄 및 러시아에 판매
                                            예정입니다.
                                        </p>
                                    </div>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요기능
                                        </h4>
                                        <p>
                                            · 기초화학 VR실험 및 이론 (한국어,
                                            영어, 러시아어 버전)
                                            <br />· 유기화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 물리화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 고급 유기화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 고급 물리화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 무기화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 분석화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 전기화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 고급 분석화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 고급 전기화학 VR실험 및 이론
                                            (한국어, 영어, 러시아어 버전)
                                            <br />· 개별 수요처 별 하드웨어 관리
                                            및 유지보수
                                        </p>
                                    </div>
                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요 연동 기능 및 효과
                                        </h4>
                                        <p>
                                            · 러닝시그널 캔버스 LMS와 VR콘텐츠
                                            연동 기능 (실험 수행도 평가 및 자동
                                            점수 기록)
                                            <br />· 수행도에 따른 자동개입 기능
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductDetailsChemVr;
