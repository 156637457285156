import React, { Component } from "react";
import { Link } from "react-router-dom";


class ProductsCardVrMetaverse extends Component {
    render() {
        return (
            <>
                <div className="projects-area ptb-100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/chemvr.jpg")}
                                        alt="image"
                                    />

                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-chemvr">
                                                <a>Chem VR</a>
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            VR & Metaverse
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/medicalvr.jpg")}
                                        alt="image"
                                    />

                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-medicalvr">
                                                <a>Medical VR</a>
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            VR & Metaverse
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductsCardVrMetaverse;
