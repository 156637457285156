import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ProductDetailsMedicalVr from "../components/ProjectDetails/ProductDetailsMedicalVr";
import Footer from "../components/Layout/Footer";

class MedicalVrDetails extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="Medical VR"
                    breadcrumbTextOne="VR & Metaverse"
                    breadcrumbUrl="/product-vrmetaverse"
                    breadcrumbTextTwo="Medical VR"
                />

                <ProductDetailsMedicalVr />

                <Footer />
            </>
        );
    }
}

export default MedicalVrDetails;
