import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ProductsCardVrMetaverse from "../components/Projects/ProductsCardVrMetaverse";
import Footer from "../components/Layout/Footer";

class ProductEducationalVr extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="VR & Metaverse"
                    breadcrumbTextOne="Product"
                    breadcrumbUrl="/product-vrmetaverse#"
                    breadcrumbTextTwo="VR & Metaverse"
                    pageSummary="(주)러닝시그널은 모든 학생의 성공을 지향합니다. 실험시스템의 구축은 학교 및 기업에서 가장 비용이 많이 드는 교육방식입니다. 러닝시그널은 VR과 Metaverse기술을 이용하여, 저렴하고 검증된 실험/실기 교육 시스템을 개발 판매하고 있으며, 기업별 요구사항에 맞는 in-house 개발도 수행하고 있습니다. "
                />

                <ProductsCardVrMetaverse />

                <Footer />
            </>
        );
    }
}

export default ProductEducationalVr;
