import React, { Component } from "react";

class ProjectDetailsRics extends Component {
    state = {
        isOpen: false,
        photoIndex: 0,
        isOpenImage: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        // const { photoIndex, isOpenImage } = this.state;
        return (
            <>
                <div className="project-details-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/rics.jpg")}
                                        alt="projects"
                                    />

                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.openModal();
                                        }}
                                    ></div>
                                </div>
                            </div>
                            {/* 
                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/project6.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div> */}

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>
                                        Real-time learning Information
                                        Collection System <br />
                                        (RICS: nicknamed as “Learning Traffic
                                        Light”: 실시간 학습정보 수집시스템 -
                                        신호등시스템 )
                                    </h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            PassFinder의 신호등 시스템은
                                            녹화강의 동영상, 실시간 화상강의
                                            등에서 Differential Privacy기법으로
                                            보안화하여 수업 중 학습자의
                                            학습정보를 수집하여, 교수자에게
                                            실시간 (혹은 녹화 강의동영상의 경우,
                                            리포트 형식으로)으로 제공하여,
                                            교수자들이 자신의 교수법이 잘
                                            작동하고 있는지를 알려줍니다.
                                            학생들은 동영상 수업 및 실시간 화상
                                            수업 중에 해당 내용에 대한
                                            학습상태정보 (“모르겠어요”,
                                            “어려워요”, “헷갈려요” 등)를
                                            신호등을 이용해서 교수자에게 알릴 수
                                            있습니다. 이 학습상태정보를
                                            머신러닝이 수집, 분석, 가공하여
                                            교수자에게 해당 교안 및 강의에 대한
                                            학생들의 반응을 실시간으로 제공하고,
                                            학생들의 개별적 학습상태에 대한
                                            정보들은 딥러닝을 이용하여, 학생에게
                                            관련 보조 수업 정보를 자동
                                            제공합니다.
                                        </p>
                                    </div>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요기능
                                        </h4>
                                        <p>
                                            · (동영상, 실시간 화상, 강의실 수업)
                                            강의에 대한 학생들의 학습 상태
                                            머신러닝 분석 및 시각화 : 예) 교안
                                            32페이지 - 어려워요 35%, 헷갈려요
                                            65% 등 <br />· Differential Privacy
                                            기법으로 보안화된 학생별 학습 상태
                                            머신러닝 예측 : 예) 전자공학과
                                            학생의 30%가 해당 주제를 어려워함.
                                            3학년 “사회조사방법론” 수업을 듣지
                                            않은 학생의 70%가 어려워함. 추후
                                            해당 학생들에게 수업 관련 보조 자료
                                            자동 발송 <br />· 머신러닝 예측 및
                                            딥러닝 분석에 기반한 인공지능 자동
                                            피드백 : 예) 해당 교안 및 주제에
                                            대한 이해도가 낮은 학생에게 자동
                                            문자/이메일/SNS를 제공 (PassFinder의
                                            AAII 이용) <br />· 학습 리뷰 기능 :
                                            강의 종료 시 학습자들이 어려워 했던
                                            부분으로 자동 이동 기능 제공 <br />·
                                            강의 리포팅 기능: 해당 강의 전체에
                                            대한 학생들의 학습 상태 분석 상세
                                            보고서 제공
                                        </p>
                                    </div>
                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요 연동 기능 및 효과
                                        </h4>
                                        <p>
                                            · Collaborative filtering 기능을
                                            활용한 강의 리뷰 기능
                                            <br />· PassFinder의 AAII와 연동
                                            메시지 (문자/이메일/SNS) 발송 기능
                                            <br />· 러닝시그널 캔버스 LMS 사용
                                            시 학생들에게 제공되는 중요 메시지
                                            “강의 콘텐츠” 이동 및 자동 편집 기능
                                            <br />· 학생별 학습 상태 분석 제공:
                                            수업 노트와 연동하여 자신의 학습상태
                                            기록 기능
                                            <br />· 교안 및 강의 평가 기능
                                            <br />· 강의 평가 기능 (기관용)
                                        </p>
                                    </div>
                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            추가개발 예정
                                        </h4>
                                        <p>
                                            · 웹기반 신호등시스템 업데이트 예정
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProjectDetailsRics;
