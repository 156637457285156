import "./assets/css/bootstrap.min.css";
import "./assets/css/fontawesome.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/flaticon.css";
import "./assets/css/boxicons.min.css";
import "../node_modules/react-modal-video/scss/modal-video.scss";
import "../node_modules/react-modal-video/css/modal-video.min.css";

import "./assets/css/style.css";
import "./assets/css/responsive.css";

import React from "react";
import { Route, Switch } from "react-router-dom";
import Index from "./pages/index";
import About from "./pages/about";
import Request from "./pages/request";
import Contact from "./pages/contact";
import Partner from "./pages/partner";
import Team from "./pages/team";
import Error from "./pages/Error";
import BrochureDownload from "./pages/BrochureDownload";
import ProductDetailsAaii from "./pages/ProductDetailsAaii";
import ProductDetailsCanvaslms from "./pages/ProductDetailsCanvaslms";
import ProductDetailsChemvr from "./pages/ProductDetailsChemvr";
import ProductDetailsLearninganalytics from "./pages/ProductDetailsLearninganalytics";
import ProductDetailsMedicalvr from "./pages/ProductDetailsMedicalvr";
import ProductDetailsRics from "./pages/ProductDetailsRics";
import ProductLmsanalytics from "./pages/ProductLmsanalytics";
import ProductPassfinder from "./pages/ProductPassfinder";
import ProductVrmetaverse from "./pages/ProductVrmetaverse";

function App() {
    return (
        <>
            <Switch>
                <Route path="/about" component={About} />
                <Route path="/request" component={Request} />
                <Route path="/brochure-download" component={BrochureDownload} />
                <Route
                    path="/product-details-aaii"
                    component={ProductDetailsAaii}
                />
                <Route
                    path="/product-details-canvaslms"
                    component={ProductDetailsCanvaslms}
                />
                <Route
                    path="/product-details-chemvr"
                    component={ProductDetailsChemvr}
                />
                <Route
                    path="/product-details-learninganalytics"
                    component={ProductDetailsLearninganalytics}
                />
                <Route
                    path="/product-details-medicalvr"
                    component={ProductDetailsMedicalvr}
                />
                <Route
                    path="/product-details-rics"
                    component={ProductDetailsRics}
                />
                <Route
                    path="/product-lmsanalytics"
                    component={ProductLmsanalytics}
                />
                <Route
                    path="/product-passfinder"
                    component={ProductPassfinder}
                />
                <Route
                    path="/product-vrmetaverse"
                    component={ProductVrmetaverse}
                />
                <Route path="/contact" component={Contact} />
                <Route path="/partner" component={Partner} />
                <Route path="/team" component={Team} />
                <Route exact path="/" component={Index} />
                <Route path="*" component={Error} />
            </Switch>
        </>
    );
}

export default App;
