import React, { Component } from "react";
import { Link } from "react-router-dom";


class ProductsCardPassFinder extends Component {
    render() {
        return (
            <>
                <div className="projects-area ptb-100">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/rics.jpg")}
                                        alt="image"
                                    />

                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-rics">
                                                <a>
                                                    Real-time learning
                                                    Information Collection
                                                    System
                                                </a>
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            PassFinder
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 col-sm-6">
                                <div className="single-projects-box">
                                    <img
                                        src={require("../../images/projects-image/aaii.jpg")}
                                        alt="image"
                                    />

                                    <div className="projects-content">
                                        <h3>
                                            <Link to="/product-details-aaii">
                                                <a>
                                                    Automated AI-Informed
                                                    Intervention
                                                </a>
                                            </Link>
                                        </h3>
                                        <span className="category">
                                            PassFinder
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductsCardPassFinder;
