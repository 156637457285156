import React, { Component } from "react";
import Navbar from "../components/Layout/Navbar";
import PageHeader from "../components/Common/PageHeader";
import ProductDetailsCanvasLms from "../components/ProjectDetails/ProductDetailsCanvasLms";
import Footer from "../components/Layout/Footer";

class CanvasLmsDetails extends Component {
    render() {
        return (
            <>
                <Navbar />

                <PageHeader
                    pageTitle="LearningSignal Canvas LMS"
                    breadcrumbTextOne="LMS & Analytics"
                    breadcrumbUrl="/product-lmsanalytics"
                    breadcrumbTextTwo="LearningSignal Canvas LMS"
                />

                <ProductDetailsCanvasLms />

                <Footer />
            </>
        );
    }
}

export default CanvasLmsDetails;
