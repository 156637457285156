import React, { Component } from "react";

class ProductDetailsCanvasLms extends Component {
    state = {
        isOpen: false,
        photoIndex: 0,
        isOpenImage: false,
    };

    openModal = () => {
        this.setState({ isOpen: true });
    };

    render() {
        // const { photoIndex, isOpenImage } = this.state;
        return (
            <>
                <div className="project-details-area ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/canvaslms.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-6">
                                <div className="project-details-image">
                                    <img
                                        src={require("../../images/projects-image/project6.jpg")}
                                        alt="projects"
                                    />
                                </div>
                            </div> */}

                            <div className="col-lg-12 col-md-12">
                                <div className="projects-details-desc">
                                    <h3>
                                        LearningSignal Canvas LMS <br />
                                        (인공지능기반 Active Learning Platform)
                                    </h3>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            개요
                                        </h4>
                                        <p>
                                            학습 설계, 학습 운용, 학습활동
                                            분석을 최적화할 수 있는 오픈소스
                                            플랫폼인 캔버스를 한국 상황에 맞도록
                                            만든 Active Learning 플랫폼입니다.
                                            학생별 Adaptive AI엔진과,
                                            Intervention AI엔진, Analytics
                                            AI엔진이 탑재된 가장 이상적인 LMS
                                            제품입니다. 기존 SIS
                                            (학생정보시스템)과 연동하여, 학생별
                                            조기경보, 커리큘럼 설계 및 교과목
                                            추천, Adaptive learning이 가능하도록
                                            교수자 및 기관에 직관적인 대시보드
                                            인터페이스를 제공합니다. 또한 VR
                                            콘텐츠와의 자동 연동 기능을
                                            추가하여, 가상현실 실험 및 시험도
                                            수행할 수 있도록 구성되어 있습니다
                                            (VR & Metaverse Applications 참조).
                                        </p>
                                    </div>

                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요기능
                                        </h4>
                                        <p>
                                            · 강의 개설/관리/운용 등 종합적인
                                            LMS 기능
                                            <br />· 러닝시그널의 LRS 템플릿 변환
                                            기능
                                            <br />· Learning analytics 기반
                                            직관적인 대시보드 제공
                                            <br />· Education Analytics 탑재
                                            (학생별 조기경보, 자동학습개입,
                                            자동학습관리, Adaptive education
                                            지원)
                                            <br />· CMS (Contents Management
                                            System) / LCMS - Panopto (파놉토
                                            탑재)
                                            <br />· PassFinder 탑재
                                        </p>
                                    </div>
                                    <div className="features-text">
                                        <h4>
                                            <i className="flaticon-tick"></i>{" "}
                                            주요 연동 기능 및 효과
                                        </h4>
                                        <p>
                                            · SIS (학사정보시스템)과의 연동을
                                            통한 학생별 학습 예측 및 관리
                                            <br />· LRS와의 연동을 통한 기관별
                                            Education Analytics 최적화
                                            <br />· PassFinder 시스템과의 연동
                                            <br />· VR콘텐츠 탑재 및 운용 기능
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default ProductDetailsCanvasLms;
